<template>
  <div
    v-if="show"
    id="HeaderPannel"
    class="header-block"
  >
    <div class="header-pannel fw-normal">
      <div
        v-for="block of pageData.blocks"
        :key="block.name"
        class="header-pannel-block fs-small"
      >
        <div
          v-if="block.name"
          class="header-pannel-field-name"
        >
          {{ $filters.localizeFilter(block.name).toUpperCase() }}
        </div>

        <div
          v-if="pageData.sortField && pageData.sortDirection"
          :key="pageData.array.length"
          v-tooltip.bottom="{
            text: pageData.array.length > 1 ? $filters.localizeFilter(block.sortTooltipText) : '',
            padding: 5,
            startAfter: 300
          }"
          class="square-0_875"
          @click.prevent.stop="reSort(block.name)"
        >
          <SortIcons
            :direction="pageData.sortField !== block.name ? null : pageData.sortDirection"
            :class="{'cursor-pointer': block.sortClickable }"
          />
        </div>

        <div
          v-if="pageData.filterByFields && Object.keys(pageData.filterByFields).length && block.name && !block.noFilter"
          :key="pageData.filterByFields[block.name]"
          v-tooltip.bottom="{
            text: pageData.filterByFields[block.name] ? $filters.localizeFilter('ClearFilter') : $filters.localizeFilter('SetFilter'),
            padding: 5,
            startAfter: 300
          }"
          class="square-1_1"
          @click.prevent.stop="filterButtonClicked(block.name)"
        >
          <FilterIcons
            :filtered="pageData.filterByFields[block.name]"
            :class="{'cursor-pointer': ((pageData.array.length <= 1 && pageData.filterByFields[block.name]) || (pageData.array.length > 1))}"
          />
        </div>
      </div>

      <div class="header-pannel-block header-search-block">
        <div>
          <div
            class="icon icon-search square-1_1"
            :class="{
              'cursor-pointer': (pageData.array.length > 1),
              'header-pannel-icon-active': searchStr,
              'header-pannel-icon-inactive': !searchStr
            }"
            @click.prevent.stop="focusSearchField()"
          />
        </div>

        <input
          v-model.trim="searchStr"
          ref="searchStr"
          type="text"
          spellcheck="false"
          :placeholder="$filters.localizeFilter('Search')"
          class="search-field-input"
        >

        <div v-if="searchStr">
          <div
            class="icon icon-close cursor-pointer header-pannel-icon-active square-1_1"
            @click.prevent.stop="clearSearchStrClicked()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { logAnalyticsEvent } from '@/firebase/analytics'

export default {
  name: 'HeaderPannelComponent',
  components: {
    FilterIcons: defineAsyncComponent(() => import('@/components/UI/buttons/FilterIcons')),
    SortIcons: defineAsyncComponent(() => import('@/components/UI/buttons/SortIcons'))
  },
  computed: {
    ...mapGetters([
      'accountsFilteredBySections',
      'accountsFilters',
      'accountsSort',
      'banksFilters',
      'cardsFilters',
      'cardsFilteredBySections',
      'cardsSort',
      'filteredAccounts',
      'filteredMoneyBoxes',
      'filteredBanks',
      'filteredCards',
      'filteredTags',
      'filteredTagsCollections',
      'filteredTransactions',
      'loadingPage',
      'moneyBoxesFilteredBySections',
      'moneyBoxesFilters',
      'moneyBoxesSort',
      'online',
      'tagsFilters',
      'tagsCollectionsFilters',
      'tagsCollectionsSort',
      'tagsSort',
      'transactions',
      'transactionsFilteredBySections',
      'transactionsFilters',
      'transactionsSort'
    ]),
    searchStr: {
      get () {
        if (!this.show) { return '' }
        return this.pageData.searchStr
      },
      set (value) {
        this.$store.commit(this.pageData.searchStrInputMutation, value)
      }
    },
    route () {
      return this.$route
    },
    show () {
      if (!this.route || !this.route.name || !this.online || this.loadingPage || !this.pages[this.route.name]) { return false }
      return true
    },
    pageData () {
      if (!this.show) { return {} }
      return this.pages[this.route.name]
    },
    pages() {
      return {
        'Accounts': {
          array: this.filteredAccounts,
          filterByFields: this.accountsFilteredBySections,
          clearFilterMutation: 'clearAccountsOneFilter',
          setFilterSectionMutation: 'setAccountsFiltersSection',
          showFiltersAction: 'showFilterAccountsButtonClicked',
          sortField: this.accountsSort.field,
          sortDirection: this.accountsSort.direction,
          setSortFieldMutation: 'setAccountsSortField',
          setSortDirectionMutation: 'setAccountsSortDirection',
          searchStr: this.accountsFilters.searchStr,
          searchStrInputMutation: 'setAccountsFiltersSearchStr',
          blocks: [
            {
              name: 'Account',
              sortTooltipText: 'SortByName',
              sortClickable: this.filteredAccounts.length > 1
            },
            {
              name: 'Currency',
              sortTooltipText: 'SortByCurrency',
              sortClickable: this.filteredAccounts.length > 1
            },
            {
              name: 'Sum',
              sortTooltipText: 'SortBySum',
              sortClickable: this.filteredAccounts.length > 1,
              noFilter: true
            },
            {
              name: 'Bank',
              sortTooltipText: 'SortByBankName',
              sortClickable: this.filteredAccounts.length > 1,
            }
          ]
        },
        'MoneyBoxes': {
          array: this.filteredMoneyBoxes,
          filterByFields: this.moneyBoxesFilteredBySections,
          clearFilterMutation: 'clearMoneyBoxesOneFilter',
          setFilterSectionMutation: 'setMoneyBoxesFiltersSection',
          showFiltersAction: 'showFilterMoneyBoxesButtonClicked',
          sortField: this.moneyBoxesSort.field,
          sortDirection: this.moneyBoxesSort.direction,
          setSortFieldMutation: 'setMoneyBoxesSortField',
          setSortDirectionMutation: 'setMoneyBoxesSortDirection',
          searchStr: this.moneyBoxesFilters.searchStr,
          searchStrInputMutation: 'setMoneyBoxesFiltersSearchStr',
          blocks: [
            {
              name: 'MoneyBox',
              sortTooltipText: 'SortByMoneyBox',
              sortClickable: this.filteredMoneyBoxes.length > 1,
              noFilter: true
            }
          ]
        },
        'Banks': {
          array: this.filteredBanks,
          searchStr: this.banksFilters.searchStr,
          searchStrInputMutation: 'setBanksFiltersSearchStr'
        },
        'Cards': {
          array: this.filteredCards,
          filterByFields: this.cardsFilteredBySections,
          clearFilterMutation: 'clearCardsOneFilter',
          setFilterSectionMutation: 'setCardsFiltersSection',
          showFiltersAction: 'showFilterCardsButtonClicked',
          sortField: this.cardsSort.field,
          sortDirection: this.cardsSort.direction,
          setSortFieldMutation: 'setCardsSortField',
          setSortDirectionMutation: 'setCardsSortDirection',
          searchStr: this.cardsFilters.searchStr,
          searchStrInputMutation: 'setCardsFiltersSearchStr',
          blocks: [
            {
              name: 'Card',
              sortTooltipText: 'SortByName',
              sortClickable: this.filteredCards.length > 1,
              noFilter: true
            },
            {
              name: 'Account',
              sortTooltipText: 'SortByAccount',
              sortClickable: this.filteredCards.length > 1,
            },
            {
              name: 'Currency',
              sortTooltipText: 'SortByCurrency',
              sortClickable: this.filteredCards.length > 1
            }
          ]
        },
        'Tags': {
          array: this.filteredTags,
          filterByFields: null,
          clearFilterMutation: null,
          setFilterSectionMutation: null,
          showFiltersAction: null,
          sortField: this.tagsSort.field,
          sortDirection: this.tagsSort.direction,
          setSortFieldMutation: 'setTagsSortField',
          setSortDirectionMutation: 'setTagsSortDirection',
          searchStr: this.tagsFilters.searchStr,
          searchStrInputMutation: 'setTagsFiltersSearchStr',
          blocks: [
            {
              name: 'Tag',
              sortTooltipText: 'SortByName',
              sortClickable: this.filteredTags.length > 1
            }
          ]
        },
        'TagsCollections': {
          array: this.filteredTagsCollections,
          filterByFields: null,
          clearFilterMutation: null,
          setFilterSectionMutation: null,
          showFiltersAction: null,
          sortField: this.tagsCollectionsSort.field,
          sortDirection: this.tagsCollectionsSort.direction,
          setSortFieldMutation: 'setTagsCollectionsSortField',
          setSortDirectionMutation: 'setTagsCollectionsSortDirection',
          searchStr: this.tagsCollectionsFilters.searchStr,
          searchStrInputMutation: 'setTagsCollectionsFiltersSearchStr',
          blocks: [
           {
              name: 'Collection',
              sortTooltipText: 'SortByName',
              sortClickable: this.filteredTagsCollections.length > 1
            }
          ]
        },
        'Transactions': {
          array: this.filteredTransactions,
          filterByFields: this.transactionsFilteredBySections,
          clearFilterMutation: 'clearTransactionsOneFilter',
          setFilterSectionMutation: 'setTransactionsFiltersSection',
          showFiltersAction: 'showFilterTransactionsButtonClicked',
          sortField: this.transactionsSort.field,
          sortDirection: this.transactionsSort.direction,
          setSortFieldMutation: 'setTransactionsSortField',
          setSortDirectionMutation: 'setTransactionsSortDirection',
          searchStr: this.transactionsFilters.searchStr,
          searchStrInputMutation: 'setTransactionsFiltersSearchStr',
          blocks: [
            {
              name: 'Date',
              sortTooltipText: 'SortByDate',
              sortClickable: this.filteredTransactions.length > 1
            },
            {
              name: 'Sum',
              sortTooltipText: 'SortBySum',
              sortClickable: this.filteredTransactions.length > 1
            },
            {
              name: 'Account',
              sortTooltipText: 'SortByAccount',
              sortClickable: this.filteredTransactions.length > 1
            },
            {
              name: 'Tags',
              sortTooltipText: 'SortByNumberOfTags',
              sortClickable: this.filteredTransactions.length > 1
            }
          ]
        }
      }
    }
  },
  methods: {
    focusSearchField () {
      if (this.pageData.array && this.pageData.array.length > 1 && this.$refs.searchStr && this.$refs.searchStr.focus) {
        this.$refs.searchStr.focus()
      }
    },
    clearSearchStrClicked() {
      this.searchStr = ''
      this.focusSearchField()
    },
    filterButtonClicked(section) {
      if (!section) { return }

      const filtered = this.pageData.filterByFields[section]

      if (!filtered && this.pageData.array.length < 2) { return }

      if (filtered) {
        this.$store.commit(this.pageData.clearFilterMutation, section)
        this.$store.commit(this.pageData.setFilterSectionMutation, null)
      } else {
        if (this.route.name === 'Transactions' && section === 'Tags') {
          const transactionsWithTags = Object.keys(this.transactions).filter(transactionId => (this.transactions[transactionId].tags && this.transactions[transactionId].tags.length))
          if (!transactionsWithTags.length) {
            this.$store.dispatch('showToast', { type: 'warning', text: this.$filters.localizeFilter('NoMatchingTransactions') })
            return
          }
        }

        this.$store.dispatch(this.pageData.showFiltersAction, { section, value: true })
      }
    },
    reSort(field) {
      if (this.pageData.array.length < 2 || !field) { return }

      if (this.pageData.sortField === field) {
        if (this.pageData.sortDirection === 'asc') {
          this.$store.commit(this.pageData.setSortDirectionMutation, 'desc')
        } else {
          this.$store.commit(this.pageData.setSortDirectionMutation, 'asc')
        }
      } else {
        this.$store.commit(this.pageData.setSortFieldMutation, field)

        let direction = 'asc'
        if (this.route.name === 'Transactions') {
          if (field === 'Date') { direction = 'desc' }
        }

        this.$store.commit(this.pageData.setSortDirectionMutation, direction)
      }

      logAnalyticsEvent(`reSortClicked`, { page: this.route.name, field: field })
    }
  }
}
</script>