<template>
  <div
    v-if="!loading"
    v-click-outside="hidePanel"
    id="databar"
    class="databar-pannel rounded-top-0_375"
    :class="{
      'databar-pannel-opened': dataBarIsOpen,
      'databar-pannel-hidden cursor-pointer': !dataBarIsOpen
    }"
    @click.prevent.stop="showDataBar()"
  >
    <div
      class="overflow-x-hidden"
      :class="{'flex_column': dataBarIsOpen, 'flex_row': !dataBarIsOpen}"
    >
      <div
        class="flex_row h_auto"
        :class="{'mr-0_25': !dataBarIsOpen}"
      >
        <div
          v-if="debtsByAccountId.length"
          v-tooltip.top="{
            text: dataBarIsOpen ? $filters.localizeFilter('NegativeBalanceOnSomeAccounts') : '',
            padding: 2,
            startAfter: 300
          }"
          :key="dataBarIsOpen + ''"
          class="databar-pannel-icon icon icon-orange icon-warning cursor-help button-appear"
          @click.prevent.stop="showInfoToast($filters.localizeFilter('NegativeBalanceOnSomeAccounts'))"
        />

        <div
          v-if="expiredCards.length && getLimitNumber('maxCards')"
          v-tooltip.top="{
            text: dataBarIsOpen ? $filters.localizeFilter('HaveExpiredCards') : '',
            padding: 5,
            startAfter: 300
          }"
          :key="dataBarIsOpen + ''"
          class="databar-pannel-icon icon icon-red icon-credit_card cursor-help button-appear"
          @click.prevent.stop="showInfoToast($filters.localizeFilter('HaveExpiredCards'))"
        />

        <div
          v-if="expiringCards.length && getLimitNumber('maxCards')"
          v-tooltip.top="{
            text: dataBarIsOpen ? $filters.localizeFilter('HaveExpiringCards') : '',
            padding: 5,
            shown: lessonStep === 'DataBarIcons',
            startAfter: 300
          }"
          :key="dataBarIsOpen + ''"
          class="databar-pannel-icon icon icon-yellow icon-credit_card cursor-help button-appear"
          @click.prevent.stop="showInfoToast($filters.localizeFilter('HaveExpiringCards'))"
        />
      </div>
      
      <div
        class="overflow-dots my-0_125"
        :class="{'flex_column ml-0_5': dataBarIsOpen, 'flex_row height_1 mr-0_5': !dataBarIsOpen}"
      >
        <div
          v-if="$route.name === 'Transactions'"
          class="databar-header-block flex_row"
          :class="{'databar-border-right': !dataBarIsOpen}"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('RowsShown') }}:
          </span>

          <span
            v-tooltip.right="{
              text: dataBarIsOpen ? $filters.localizeFilter('NumberOfLoadedTransactions') : '',
              padding: 4,
              startAfter: 300
            }"
            :key="dataBarIsOpen"
            class="databar-text mr-0_25 ml-0_25"
          >
            {{ filteredTransactions.length }}
          </span>

          <span
            v-if="userStats('transactions') !== null"
            class="flex_row"
          >
            <span class="databar-text mr-0_25 opacity-0_75 text_nowrap">
              <span class="mr-0_25">{{ $filters.localizeFilter('Of') }}</span>

              <span
                v-tooltip.right="{
                  text: dataBarIsOpen ? $filters.localizeFilter('TotalNumberOfTransactions') : '',
                  padding: 4,
                  startAfter: 300
                }"
                :key="dataBarIsOpen"
              >
                {{ userStats('transactions') }}
              </span>
            </span>
          </span>
        </div>

        <div
          v-if="$route.name === 'Transactions' && Object.keys(totalTransactionsSums).length"
          class="databar-header-block overflow-dots"
          :class="{'flex_column': dataBarIsOpen, 'flex_row': !dataBarIsOpen}"
        >
          <div
            v-tooltip.top="{
              text: dataBarIsOpen ? $filters.localizeFilter('ByLoadedTransactions') : '',
              padding: 4,
              startAfter: 300
            }"
            :key="dataBarIsOpen + ''"
            class="databar-header-text"
            :class="{'mb-1_5': !dataBarIsOpen}"
          >
            {{ $filters.localizeFilter('SumByRows') }}:
          </div>

          <div
            class="databar-text"
            :class="{'flex_column ml-1_0': dataBarIsOpen, 'flex_row': !dataBarIsOpen}"
          >
            <div
              v-for="(sum, currCode) in totalTransactionsSums"
              :key="currCode"
              class="mr-0_25"
              :class="{'databar-border-right': !dataBarIsOpen && Object.keys(totalTransactionsSums).length > 1}"
            >
              <span
                v-tooltip.top="{
                  text: dataBarIsOpen ? (currencies[currCode].locales[locale] || '') : '',
                  startAfter: 300
                }"
                :key="dataBarIsOpen"
                class="mx-0_25"
              >
                {{  $filters.currencyFilter(sum, currCode) }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="$route.name === 'Accounts'"
          class="databar-header-block flex_row"
          :class="{'databar-border-right': !dataBarIsOpen}"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('ShownAccounts') }}:
          </span>

          <span class="databar-text mr-0_25 ml-0_25">
            {{ filteredAccounts.length }}
          </span>

          <span
            v-if="userStats('accounts') !== null"
            class="flex_row"
          >
            <span class="databar-text mr-0_25 opacity-0_75 text_nowrap">
              <span class="mr-0_25">{{ $filters.localizeFilter('Of') }}</span>

              <span
                v-tooltip.right="{
                  text: dataBarIsOpen ? $filters.localizeFilter('TotalNumberOfAccounts') : '',
                  padding: 5,
                  startAfter: 300
                }"
                :key="dataBarIsOpen"
              >
                {{ userStats('accounts') }}
              </span>
            </span>
          </span>
        </div>

        <div
          v-if="$route.name === 'Accounts' && Object.keys(totalFilteredAccountsSums).length"
          class="width_max_content"
          :class="{'flex_column': dataBarIsOpen, 'flex_row': !dataBarIsOpen}"
        >
          <span
            v-tooltip.top="{
              text: dataBarIsOpen ? $filters.localizeFilter('ByShownAccounts') : '',
              padding: 1,
              startAfter: 300
            }"
            :key="dataBarIsOpen"
            class="databar-header-text"
          >
            {{ $filters.localizeFilter('TotalSum') }}:
          </span>
          
          <span
            class="databar-text"
            :class="{'flex_column ml-1_0': dataBarIsOpen, 'flex_row': !dataBarIsOpen}"
          >
            <span
              v-for="(sum, currCode) in totalFilteredAccountsSums"
              :key="currCode"
              class="mr-0_25"
              :class="{'databar-border-right': !dataBarIsOpen && Object.keys(totalFilteredAccountsSums).length > 1}"
            >
              <span
                v-tooltip.top="{
                  text: dataBarIsOpen ? (currencies[currCode].locales[locale] || '') : '',
                  padding: 1,
                  startAfter: 300
                }"
                :key="dataBarIsOpen"
                class="mx-0_25"
              >
                {{  $filters.currencyFilter(sum, currCode) }}
              </span>
            </span>

            <span
              v-if="dataBarIsOpen && defaultCurrency && rates && (Object.keys(totalFilteredAccountsSums).length > 1 || (Object.keys(totalFilteredAccountsSums)[0] !== defaultCurrency))"
              v-tooltip.top="{
                text: dataBarIsOpen ? $filters.localizeFilter('TotalInMainCurrency') : '',
                padding: 1,
                startAfter: 300
              }"
              :key="dataBarIsOpen"
              class="ml-0_25"
            >
              {{ $filters.localizeFilter('Total') }}: {{ $filters.currencyFilter($filters.totalSumFilter(totalFilteredAccountsSums, defaultCurrency, rates), defaultCurrency) }}
            </span>
          </span>
        </div>

        <div
          v-if="$route.name === 'Tags'"
          class="databar-header-block flex_row"
          :class="{'databar-border-right': !dataBarIsOpen}"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('TotalNumberOfTags') }}:
          </span>

          <span
            v-if="userStats('tags') !== null"
            class="flex_row"
          >
            <span
              v-tooltip.right="{
                text: dataBarIsOpen ? $filters.localizeFilter('TotalNumberOfTags') : '',
                padding: 5,
                startAfter: 300
              }"
              :key="dataBarIsOpen"
              class="databar-text mr-0_25 ml-0_25 opacity-0_75 text_nowrap"
            >
              {{ userStats('tags') }}
            </span>
          </span>
        </div>

        <div
          v-if="$route.name === 'TagsCollections' && getLimitNumber('maxTagsCollections')"
          class="databar-header-block flex_row"
          :class="{'databar-border-right': !dataBarIsOpen}"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('TotalNumberOfTagsCollections') }}:
          </span>

          <span
            v-if="userStats('tagsCollections') !== null"
            class="flex_row"
          >
            <span
              v-tooltip.right="{
                text: dataBarIsOpen ? $filters.localizeFilter('TotalNumberOfTagsCollections') : '',
                padding: 5,
                startAfter: 300
              }"
              :key="dataBarIsOpen"
              class="databar-text mr-0_25 ml-0_25 opacity-0_75 text_nowrap"
            >
              {{ userStats('tagsCollections') }}
            </span>
          </span>
        </div>

        <div
          v-if="$route.name === 'MoneyBoxes' && getLimitNumber('maxMoneyBoxes')"
          class="databar-header-block flex_row"
          :class="{'databar-border-right': !dataBarIsOpen}"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('TotalNumberOfMoneyBoxes') }}:
          </span>

          <span
            v-if="userStats('moneyBoxes') !== null"
            class="flex_row"
          >
            <span
              v-tooltip.right="{
                text: dataBarIsOpen ? $filters.localizeFilter('TotalNumberOfMoneyBoxes') : '',
                padding: 5,
                startAfter: 300
              }"
              :key="dataBarIsOpen"
              class="databar-text mr-0_25 ml-0_25 opacity-0_75 text_nowrap"
            >
              {{ userStats('moneyBoxes') }}
            </span>
          </span>
        </div>

        <div
          v-if="$route.name === 'Banks' && getLimitNumber('maxBanks')"
          class="databar-header-block flex_row"
          :class="{'databar-border-right': !dataBarIsOpen}"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('TotalNumberOfBanks') }}:
          </span>

          <span
            v-if="userStats('banks') !== null"
            class="flex_row"
          >
            <span
              v-tooltip.right="{
                text: dataBarIsOpen ? $filters.localizeFilter('TotalNumberOfBanks') : '',
                padding: 5,
                startAfter: 300
              }"
              :key="dataBarIsOpen"
              class="databar-text mr-0_25 ml-0_25 opacity-0_75 text_nowrap"
            >
              {{ userStats('banks') }}
            </span>
          </span>
        </div>

        <div
          v-if="$route.name === 'Cards' && getLimitNumber('maxCards')"
          class="databar-header-block flex_row"
          :class="{'databar-border-right': !dataBarIsOpen}"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('TotalNumberOfCards') }}:
          </span>

          <span
            v-if="userStats('cards') !== null"
            class="flex_row"
          >
            <span
              v-tooltip.right="{
                text: dataBarIsOpen ? $filters.localizeFilter('TotalNumberOfCards') : '',
                padding: 5,
                startAfter: 300
              }"
              :key="dataBarIsOpen"
              class="databar-text mr-0_25 ml-0_25 opacity-0_75 text_nowrap"
            >
              {{ userStats('cards') }}
            </span>
          </span>
        </div>

        <div
          v-if="debtsByAccountId.length && dataBarIsOpen"
          class="flex_column"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('Debts') }}:
          </span>
          
          <span
            v-for="accountId in debtsByAccountId"
            :key="accountId + '-' + dataBarIsOpen"
            v-tooltip.top="{
              text: dataBarIsOpen ? createAccountInfo(accountId) : '',
              padding: 1,
              startAfter: 300
            }"
            class="databar-text"
            :class="{'ml-1_25': dataBarIsOpen}"
          >
            <span>{{ accounts[accountId].name }}:</span>

            <span class="ml-0_25">
              {{  $filters.currencyFilter(accounts[accountId].sum, accounts[accountId].currencyCode) }}
            </span>
          </span>

          <span
            v-if="dataBarIsOpen && defaultCurrency && rates && (Object.keys(debtsByCurrencyCode).length > 1 || (Object.keys(debtsByCurrencyCode)[0] !== defaultCurrency))"
            v-tooltip.top="{
              text: dataBarIsOpen ? $filters.localizeFilter('TotalInMainCurrency') : '',
              padding: 1,
              startAfter: 300
            }"
            :key="dataBarIsOpen"
            class="databar-text italic"
            :class="{'ml-1_25': dataBarIsOpen}"
          >
            {{ $filters.localizeFilter('Total') }}: {{ $filters.currencyFilter($filters.totalSumFilter(debtsByCurrencyCode, defaultCurrency, rates), defaultCurrency) }}
          </span>
        </div>

        <div
          v-if="userStats('transactions') !== null && $route.name !== 'Transactions'"
          v-tooltip.top="{
            text: dataBarIsOpen ? $filters.localizeFilter('InDatabase') : '',
            padding: 5,
            startAfter: 300
          }"
          :key="dataBarIsOpen"
          class="databar-header-block flex_row"
        >
          <span class="databar-header-text">
            {{ $filters.localizeFilter('TotalNumberOfTransactions') }}:
          </span>
          
          <span class="databar-text mr-0_25 ml-0_25">
            {{ userStats('transactions') }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="databar-top-right-buttons"
      :class="{'flex_column': dataBarIsOpen, 'flex_row_reverse vertical_align_center': !dataBarIsOpen}"
    >
      <div
        :key="dataBarIsOpen"
        v-tooltip.top="{
          text: dataBarIsOpen ? $filters.localizeFilter('HidePanel') : $filters.localizeFilter('ShowPanel'),
          padding: 5,
          startAfter: 300
        }"
        class="databar-open-button cursor-pointer button-appear horizontal_align_center"
        :class="{
          'icon-close': dataBarIsOpen,
          'icon-zoom_out_map': !dataBarIsOpen
        }"
        @click.prevent="hidePanel()"
      />

      <GoToHelpButton
        class="databar-help-button horizontal_align_center cursor-pointer"
        :helpsection="$route.name"
        :location="$route.name === 'MoneyBoxes' ? 'DataBarAccounts' : 'DataBar' + $route.name"
        :tooltip-location="dataBarIsOpen ? 'left' : 'top'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { logAnalyticsEvent } from '@/firebase/analytics'
import Currencies from '@/libraries/currencies'
import GoToHelpButton from '@/components/UI/buttons/GoToHelpButton'

export default {
  name: 'DataBarComponent',
  components: { GoToHelpButton },
  data: () => ({
    loading: true,
    currencies: Currencies
  }),
  computed: {
    ...mapGetters([
      'accounts',
      'banks',
      'canUseLimit',
      'dataBarIsOpen',
      'debtsByAccountId',
      'debtsByCurrencyCode',
      'defaultCurrency',
      'filteredAccounts',
      'filteredTransactions',
      'getLimitNumber',
      'lessonStep',
      'locale',
      'rates',
      'totalFilteredAccountsSums',
      'totalTransactionsSums',
      'userStats'
    ]),
    expiringCards() {
      return this.$store.getters.cardsExpire({expiring: true})
    },
    expiredCards() {
      return this.$store.getters.cardsExpire({expired: true})
    }
  },
  async created () {
    this.loading = true

    if (!this.defaultCurrency) {
      await this.$store.dispatch('fetchUserSettings')
    }

    if (this.defaultCurrency && !this.rates && this.canUseLimit('rates', false)) {
      await this.$store.dispatch('fetchRates', false)
    }

    this.loading = false
  },
  methods: {
    showDataBar () {
      if (!this.dataBarIsOpen) {
        this.$store.commit('setDataBarIsOpen', true)
        logAnalyticsEvent('dataBarOpened')
        setTimeout(() => {
          const databar = document.getElementById('databar')
          databar.classList.remove('databar-pannel-hidden')
        }, 0)
      }
    },
    hidePanel () {
      if (this.dataBarIsOpen) {
        const databar = document.getElementById('databar')
        databar.classList.add('databar-pannel-hidden')
        setTimeout(() => {
          this.$store.commit('setDataBarIsOpen', false)
          logAnalyticsEvent('dataBarClosed')
        }, 300)
      }
    },
    createAccountInfo(accountId) {
      let answer = ''
      if (accountId && this.accounts[accountId] && this.accounts[accountId].bankId && this.banks[this.accounts[accountId].bankId] && this.banks[this.accounts[accountId].bankId].name) {
        answer = this.$filters.localizeFilter('Bank') + ': ' + this.banks[this.accounts[accountId].bankId].name
      }
      return answer
    },
    showInfoToast(text) {
      if (!text) { return }
      this.$store.dispatch('showToast', { type: 'info', text })
    }
  }
}
</script>